import { navigate, useLocation } from "@reach/router";
import { Box, HStack, StyleProps, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { $email, $isarray, $isemail, $length, $ok, $UUID } from "foundation-ts/commons";
import { $password } from "foundation-ts/crypto";
import { Resp } from "foundation-ts/tsrequest";
import { Nullable, UUID } from "foundation-ts/types";
import { CorporationDto, UserDto } from "g1-commons/lib/doxecureClientTypes";
import { ConsumptionsStructure, UserProfile } from "g1-commons/lib/doxecureTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { ColumnTitle } from "../../components/ColumnTitle/ColumnTitle";
import { ConsumptionsBox } from "../../components/ConsumptionsBox/ConsumptionsBox";
import { G1Button } from "../../components/G1Button";
import { AsyncLinkUserModal } from "../../components/Modals/AsyncLinkUserModal";
import { AsyncConfirmModal, DefaultAsyncConfirmModalState } from "../../components/Modals/AsyncConfirmModal";
import { WaitingModal } from "../../components/Modals/WaitingModal";
import { PageShellWithThreeColumns } from "../../components/PageShellWithThreeColumns/PageShellWithThreeColumns";
import { UserInfos, UserParameters } from "../../components/UserInfos/UserInfos";
import { CorporationUserInfos } from "../../components/UserInfos/CorporationUserInfos";
import { SearchUsers } from "../../components/admin/SearchUsers";

import { config } from "../../config/config";
import useCreateUser from "../../hooks/useCreateUser";
import useCountries from "../../hooks/useCountries";
import useCorporations from "../../hooks/useCorporations";
import { createAdminCorporate, createCorporationUser, getCorporationUsers, linkUserToCorporation } from "../../services/corporations.service";
import { exportConsumptions, getConsumptions, getCurrentConsumption } from "../../services/stats.service";
import { createAuthentication, searchUsersFromParams, getUserProfile, getUserChannels, deleteAuthenticationChannel, setAdminRole, patchUser } from "../../services/users.service";
import { defaultErrorToast, defaultInfoToast, defaultSuccessToast, defaultWarningToast } from "../../utils/toast";
import { ActionButton } from "../../utils/TypesAndConstants";
import { forceLogout, isAdminUser } from "../../utils/functions";

interface UsersViewProps extends StyleProps {
    connectedUserProfile?: UserDto ;
}

export const UsersView = ({ maxWidth, connectedUserProfile }: UsersViewProps) => {
    const { mutateAsync: createUser } = useCreateUser() ;
    const { data: corporations } = useCorporations() ;
    const { data: countries } = useCountries() ;
    const toast = useToast() ;
    const [visibleUserInfos, setVisibleUserInfos] = useState(false) ;
    const [userToShow, setUserToShow] = useState<Nullable<UserParameters>>(null) ;
    const [actionButtons, setActionButtons] = useState<ActionButton<UserParameters>[]>([]) ;
    const [nbQualified, setNbQualified] = useState<number>(0) ;
    const [nbAdvanced, setNbAdvanced] = useState<number>(0) ;
    const [consumptions, setConsumptions] = useState<Nullable<ConsumptionsStructure>>(null) ;
    const [users, setUsers] = useState<UserParameters[]>([]) ;
    const [workedCorporateUsers, setWorkedCorporateUsers] = useState<UserParameters[]>([]) ;
    const [forceReload, setForceReload] = useState(0) ;
    const [isCorporateUserToShow, setIsCorporateUserToShow] = useState(false) ;
    const [isCreation, setIsCreation] = useState(true) ;
    const [isAddedAdminCorporate, setIsAddedAdminCorporate] = useState(false) ;
    const [isOpenLinkUserModal, setIsOpenLinkUserModal] = useState(false) ;
    const [confirmModal, setConfirmModal] = useState(DefaultAsyncConfirmModalState) ;
    const [usernameToLink, setUsernameToLink] = useState("") 
    const [userIdToLink, setUserIdToLink] = useState<Nullable<UUID>>(null) ;
    const { isOpen, onOpen, onClose } = useDisclosure() ;

    const location = useLocation() ;
    const searchParams = new URLSearchParams(location.search) ;

    const setUserButtons = (user?: Nullable<UserParameters>) => {
        const buttons: ActionButton<UserParameters>[] = [] ;
        const userFlags = user?.userFlags ;
        if (!$ok(userFlags)) {
            buttons.push({ title: "Créer", variant: "important", onSubmit: onSubmitAddUser }) ;
        } else if (!userFlags?.activated) {
            buttons.push({ title: userFlags?.profile === UserProfile.Contact ? "Activer l'accès" : "Renvoyer le lien d'activation", variant: "important", onSubmit: onSubmitSendActivationLink }) ;
        } else if (connectedUserProfile?.apid !== user?.apid) { // Only actions for other users
            switch (userFlags.profile) {
                case UserProfile.User:
                    if (connectedUserProfile?.userFlags?.profile === UserProfile.Root) {
                        buttons.push({ title: "Définir comme Administrateur", variant: "important", onSubmit: onSubmitAddAdminRole }) ;
                    }
                    buttons.push({ title: "Désactiver l'accès", variant: "important", onSubmit: onSubmitDesactivateUser }) ;
                    if (connectedUserProfile?.userFlags?.profile === UserProfile.Root) {
                        buttons.push({ title: "Rattacher à une organisation", variant: "optional", onSubmit: onSubmitLinkToCorporate }) ;
                    }
                    break ;

                case UserProfile.UserCorporate:
                    buttons.push({ title: "Définir comme Gestionnaire", variant: "important", onSubmit: onSubmitAddAdminRole }) ;
                    buttons.push({ title: "Désactiver l'accès", variant: "important", onSubmit: onSubmitDesactivateUser }) ;
                    break ;
                
                case UserProfile.Administrator :
                    if (connectedUserProfile?.userFlags?.profile === UserProfile.Root) {
                        buttons.push({ title: "Supprimer le rôle Administrateur", variant: "important", onSubmit: onSubmitDeleteAdminRole }) ;
                        buttons.push({ title: "Désactiver l'accès", variant: "important", onSubmit: onSubmitDesactivateUser }) ;
                    }
                    buttons.push({ title: "Rattacher à une organisation", variant: "optional", onSubmit: onSubmitLinkToCorporate }) ;
                    break ;

                case UserProfile.AdministratorCorporate :
                    buttons.push({ title: "Supprimer le rôle Gestionnaire", variant: "important", onSubmit: onSubmitDeleteAdminRole }) ;
                    buttons.push({ title: "Désactiver l'accès", variant: "important", onSubmit: onSubmitDesactivateUser }) ;
                    break ;

                default:
                    break ;
            }
        }
        setActionButtons(buttons) ;
    } ;

    const reloadUser = async (userId: UUID) => {
        const userParameters = await _toUserParameters(userId) ;
        setUserToShow(userParameters) ;
        setUserButtons(userParameters) ;
        const index = users.findIndex(user => user.apid === userId) ;
        if (index !== -1) {
            setUsers([]) ;
            if (isCorporateUserToShow) {
                reloadCorporateUsers() ;
            } else {
                users.splice(index, 1, userParameters) ;
                setUsers(users) ;
                setForceReload(forceReload + 1) ;
            }
        }
    } ;

    const reloadCorporateUsers = async () => {
        const users: UserDto[] = [] ;
        for (let corporation of connectedUserProfile?.corporations!) {
            const userList = await getCorporationUsers(corporation.apid) ;
            if ($ok(userList)) {
                users.push(...userList!) ;
            }
        }
        setUsers(users) ;
        setWorkedCorporateUsers(users) ;
    }

    const onSearchUser = async (search?: Nullable<string>) => {
        setUserToShow(null) ;
        setVisibleUserInfos(false) ;
        const users: UserParameters[] = [] ;
        try {
            if ($ok(search)) {
                const userIds = await searchUsersFromParams(search!, true) ;
                if (!$ok(userIds) || (userIds!.length === 0)) {
                    toast(defaultInfoToast(`Aucun utilisateur trouvé avec ${$isemail(search) ? "l'email" : "le nom"} "${search}"`)) ;
                } else {
                    for (const userId of userIds!) {
                        users.push(await _toUserParameters(userId)) ;
                    }
                    setUsers(users) ;
                }
            } else {
                setUsers(users) ;
            }
        } catch (e) {
            if ((e as Error).message === Resp.Unauthorized.toString()) {
                toast(defaultErrorToast("Erreur lors de la recherche d'utilisateurs. Soit vous avez dépassé le délai d'inactivité sur l'interface, soit vous n'êtes pas autorisé à y accéder. Vous avez été automatiquement déconnecté, veuillez-vous authentifier à nouveau.")) ;
                forceLogout(config.app.adminLoginURL!) ;
            } else {
                toast(defaultErrorToast((e as Error).message)) ;
            }
        }
    } ;

    const onSearchCorporateUser = async (search?: Nullable<string>): Promise<void> => {
        setUserToShow(null) ;
        setVisibleUserInfos(false) ;
        setWorkedCorporateUsers($ok(search) ? users!.filter(user => user.lastName.includes(search!.toUpperCase()) || user.email === search?.toLowerCase()) : users) ;
    } ;

    const onClickViewUser = async (userId: UUID) => {
        try {
            const userParameters = await _toUserParameters(userId) ;
            setUserToShow(userParameters) ;
            setVisibleUserInfos(true) ;
            setUserButtons(userParameters) ;
            setIsCreation(false) ;

            const currentConsumptions = await getCurrentConsumption(userId);
            setNbQualified($ok(currentConsumptions) ? currentConsumptions!.qualified : -1) ;
            setNbAdvanced($ok(currentConsumptions) ? currentConsumptions!.advanced : -1) ;
            setConsumptions(await getConsumptions(userId)) ;
        } catch (e) {
            if ((e as Error).message === Resp.Unauthorized.toString()) {
                toast(defaultErrorToast("Erreur lors de la récupération des données de l'utilisateur. Soit vous avez dépassé le délai d'inactivité sur l'interface, soit vous n'êtes pas autorisé à y accéder. Vous avez été automatiquement déconnecté, veuillez-vous authentifier à nouveau.")) ;
                forceLogout(config.app.adminLoginURL!) ;
            } else {
                toast(defaultErrorToast((e as Error).message)) ;
            }
        }
    } ;

    const onClickAddUser = () => {
        setUserToShow((connectedUserProfile?.userFlags?.profile === UserProfile.AdministratorCorporate) ? { lastName: "", firstName: "", email: $email("")!, corporationId: connectedUserProfile?.corporations?.first()?.apid } : null) ;
        setVisibleUserInfos(true) ;
        setIsCreation(true) ;
        setIsAddedAdminCorporate(false) ;
        setUserButtons() ;
        setIsCorporateUserToShow(connectedUserProfile?.userFlags?.profile === UserProfile.AdministratorCorporate) ;
        if (searchParams.get('view') !== null || searchParams.get("corporationId") !== null) { // Remove url param if exists when click on add user button
            const url = new URL(window.location.href) ;
            url.searchParams.delete("corporationId") ;
            url.searchParams.delete("view") ;
            window.history.replaceState({}, '', url.toString()) ;
        } 
    } ;

    const onSubmitDesactivateUser = async (data: UserParameters) => {
        setConfirmModal({
            title: "Confirmation",
            message: `Etes-vous sûr de vouloir désactiver l'accès de l'utilisateur ${data.firstName} ${data.lastName} ?`,
            onAccept: async () => {
                try {
                    const success = await deleteAuthenticationChannel(data.apid!) ;
                    if (success) {
                        toast(defaultSuccessToast(`Accès de ${data.firstName} ${data.lastName} désactivé`)) ;
                        reloadUser(data.apid!) ;
                    } else {
                        toast(defaultInfoToast(`Erreur lors de la désactivation de l'accès de ${data.firstName} ${data.lastName}`)) ;
                    }
                } catch (e) {
                    if ((e as Error).message === Resp.Unauthorized.toString()) {
                        toast(defaultErrorToast("Erreur lors de la désactivation de cet utilisateur. Soit vous avez dépassé le délai d'inactivité sur l'interface, soit vous n'êtes pas autorisé à y accéder. Vous avez été automatiquement déconnecté, veuillez-vous authentifier à nouveau.")) ;
                        forceLogout(config.app.adminLoginURL!) ;
                    } else {
                        toast(defaultErrorToast("Impossible de désactiver l'accès de cet utilisateur")) ;
                    }
                }
                setConfirmModal(DefaultAsyncConfirmModalState) ;
            },
            onClose: async () => {
                setConfirmModal(DefaultAsyncConfirmModalState) ;
            }
        }) ;
    } ;

    const onSubmitSendActivationLink = async (data: UserParameters): Promise<void> => {
        setConfirmModal({
            title: "Confirmation",
            message: `Etes-vous de vouloir ${userToShow?.userFlags?.profile == UserProfile.Contact ? "activer l'accès de" : "renvoyer le lien d'activation à"} ${data.firstName} ${data.lastName} ?`,
            onAccept: async () => {
                try {
                    if (await createAuthentication(data.apid!, $password(12, { usesDigits: true, usesLowercase: true, usesUppercase: true, usesSpecials: true }))) {
                        toast(defaultSuccessToast(`Lien d'activation pour ${data.firstName} ${data.lastName} renvoyé à l'email ${data.email.toString()}`)) ;
                        reloadUser(data.apid!) ;
                    } else {
                        toast(defaultInfoToast(`Erreur lors du renvoi du lien d'activation`)) ;
                    }
                } catch (e) {
                    if ((e as Error).message === Resp.Unauthorized.toString()) {
                        toast(defaultErrorToast("Erreur lors du renvoi du lien d'activation. Soit vous avez dépassé le délai d'inactivité sur l'interface, soit vous n'êtes pas autorisé à y accéder. Vous avez été automatiquement déconnecté, veuillez-vous authentifier à nouveau.")) ;
                        forceLogout(config.app.adminLoginURL!) ;
                    } else {
                        toast(defaultErrorToast("Impossible de renvoyer un lien d'activation à cet utilisateur")) ;
                    }
                }
                setConfirmModal(DefaultAsyncConfirmModalState) ;
            },
            onClose: async () => {
                setConfirmModal(DefaultAsyncConfirmModalState) ;
            }
        }) ;
    } ;

    const onSubmitAddUser = async (data: UserParameters) => {
        try {
            const userIds = await searchUsersFromParams(data.email) ;
            let userId = $isarray(userIds) && (userIds!.length !== 0) ? userIds![0] : null ;
            if ($ok(userId)) {
                const channelIds = await getUserChannels(userId!) ;
                if ($isarray(channelIds) && channelIds?.length !== 0) {
                    toast(defaultErrorToast("Un utilisateur avec cet email existe déjà")) ;
                    return ;
                }
            } else {
                const userDto: UserDto = {
                    firstName: data.firstName.capitalize(),
                    lastName: data.lastName.toUpperCase(),
                    email: data.email
                } ;
                if ($length(data.organizationName) !== 0) {
                    userDto.organizationName = data.organizationName?.toUpperCase() ;
                }
                if ($length(data.vatNumber) !== 0) {
                    userDto.vatNumber = data.vatNumber?.toUpperCase() ;
                }
                if ($ok(data.organizationAddress)) {
                    userDto.organizationAddress = data.organizationAddress ;
                }

                let user: UserDto ;
                if (isCorporateUserToShow) {
                    user = isAddedAdminCorporate ? await createAdminCorporate(data.corporationId!, userDto) : await createCorporationUser(connectedUserProfile?.corporations?.first()?.apid || data.corporationId!, userDto) ;
                } else {
                    user = await createUser(userDto) ;
                }
                userId = user.apid! ;
            }
            const success = await createAuthentication(userId!, $password(12, { usesDigits: true, usesLowercase: true, usesUppercase: true, usesSpecials: true })) ;
            if (success) {
                toast(defaultSuccessToast(`${isAddedAdminCorporate ? "Gestionnaire" : "Utilisateur"} ${data.firstName} ${data.lastName} créé`)) ;
                if (connectedUserProfile?.userFlags?.profile === UserProfile.AdministratorCorporate) { // Only reload corporate users when view show collaborators (in case of Administrator Corporate connected)
                    reloadCorporateUsers() ;
                } else if (isAddedAdminCorporate) {
                    navigate(`/admin/corporations?corporationId=${data.corporationId}`) ;
                }
            } else {
                toast(defaultWarningToast(`Erreur lors de l'ajout des informations d'authentification`)) ;
            }
        } catch (e) {
            if ((e as Error).message === Resp.Unauthorized.toString()) {
                toast(defaultErrorToast("Erreur lors de l'ajout d'un utilisateur. Soit vous avez dépassé le délai d'inactivité sur l'interface, soit vous n'êtes pas autorisé à y accéder. Vous avez été automatiquement déconnecté, veuillez-vous authentifier à nouveau.")) ;
                forceLogout(config.app.adminLoginURL!) ;
            } else {
                toast(defaultErrorToast((e as Error).message)) ;
            }
        }
    } ;

    const onSubmitAddAdminRole = async (data: UserParameters) => {
        const oldRole = data.userFlags?.profile ;
        setConfirmModal({
            title: "Confirmation",
            message: `Etes-vous sûr de vouloir ajouter le rôle ${oldRole === UserProfile.User ? "d'Administrateur" : "de Gestionnaire"} à l'utilisateur ${data.firstName} ${data.lastName} ?`,
            onAccept: async () => {
                if (await setAdminRole(data.apid!)) {
                    toast(defaultSuccessToast(`Une notification a été envoyée à l'utilisateur ${data.firstName} ${data.lastName} afin de le prévenir de son nouveau rôle ${oldRole === UserProfile.User ? "d'Administrateur" : "de Gestionnaire"}`)) ;
                    reloadUser(data.apid!) ;
                } else {
                    toast(defaultErrorToast(`Impossible d'ajouter le rôle ${oldRole === UserProfile.User ? "d'Administrateur" : "de Gestionnaire"} à cet utilisateur`)) ;
                }
                setConfirmModal(DefaultAsyncConfirmModalState) ;
            },
            onClose: async () => {
                setConfirmModal(DefaultAsyncConfirmModalState) ;
            }
        }) ;
    } ;

    const onSubmitDeleteAdminRole = async (data: UserParameters) => {
        const oldRole = data.userFlags?.profile ;
        setConfirmModal({
            title: "Confirmation",
            message: `Etes-vous sûr de vouloir supprimer le rôle ${oldRole === UserProfile.Administrator ? "d'Administrateur" : "de Gestionnaire"} à l'utilisateur ${data.firstName} ${data.lastName} ?`,
            onAccept: async () => {
                if (await setAdminRole(data.apid!, false)) {
                    toast(defaultSuccessToast(`Une notification a été envoyée à l'utilisateur ${data.firstName} ${data.lastName} afin de le prevénir qu'il n'est plus ${oldRole === UserProfile.Administrator ? "Administrateur" : "Gestionnaire"}`)) ;
                    reloadUser(data.apid!) ;
                } else {
                    toast(defaultErrorToast(`Impossible de supprimer le rôle ${oldRole === UserProfile.Administrator ? "d'Administrateur" : "de Gestionnaire"} à cet utilisateur`)) ;
                }
                setConfirmModal(DefaultAsyncConfirmModalState) ;
            },
            onClose: async () => {
                setConfirmModal(DefaultAsyncConfirmModalState) ;
            }
        }) ;
    } ;

    const onSubmitLinkToCorporate = async (data: UserParameters) => {
        if (corporations?.findIndex(corporation => {
            const domainPattern = corporation.domains?.map(domain => domain.replace(/\./g, '\\.')).join('|') ;
            const regex = new RegExp(`^[a-zA-Z0-9._%+-]+@(${domainPattern})$`) ;
            return regex.test(data.email) ;
        }) !== -1) {
            setIsOpenLinkUserModal(true) ;
            setUsernameToLink(`${data.firstName.capitalize()} ${data.lastName.toUpperCase()}`) ;
            setUserIdToLink(data.apid) ;
        } else {
            toast(defaultErrorToast("Aucune organisation n'est configurée pour accepter le domaine de mail de cet utilisateur")) ;
        }
    }

    const onExportUserConsumptions = async () => {
        if ($ok(userToShow)) {
            try {
                const username = `${userToShow?.firstName.capitalize()}_${userToShow?.lastName.toUpperCase()}` ;
                await exportConsumptions(userToShow?.apid!, username) ;
            } catch (e) {
                if ((e as Error).message === Resp.Unauthorized.toString()) {
                    toast(defaultErrorToast("Erreur lors de l'export des données. Soit vous avez dépassé le délai d'inactivité sur l'interface, soit vous n'êtes pas autorisé à y accéder. Vous avez été automatiquement déconnecté, veuillez-vous authentifier à nouveau.")) ;
                    forceLogout(config.app.adminLoginURL!) ;
                } else {
                    toast(defaultErrorToast("Problème rencontré lors de l'export des données")) ;
                }
            }
        }
    } ;

    const onLinkUserToCorporate = async (corporation: Nullable<CorporationDto>) => { 
        setIsOpenLinkUserModal(false) ;
        if ($ok(corporation)) {
            setConfirmModal({
                title: "Confirmation",
                message: `Etes-vous sûr de vouloir rattacher ${usernameToLink} à l'organisation '${corporation?.name}' ?`,
                onAccept: async () => {
                    setConfirmModal(DefaultAsyncConfirmModalState) ;
                    onOpen() ;
                    const newUser = await linkUserToCorporation(corporation!.apid!, userIdToLink!) ;
                    if ($ok(newUser)) {
                        const index  = newUser.email.lastIndexOf(".") ;
                        newUser.email = $email(newUser.email.substring(0, index))! ;
                        if (await patchUser(newUser) !== null) {
                            toast(defaultInfoToast(`Une notification a été envoyée à l'utilisateur ${usernameToLink} afin de lui indiquer son rattachement à l'organisation '${corporation?.name}'`)) ;
                            onSearchUser(null) ;
                        } else {
                            toast(defaultErrorToast(`Impossible de rattacher l'utilisateur ${usernameToLink} à l'organisation '${corporation?.name}'`)) ;
                        }
                    } else {
                        toast(defaultErrorToast(`Impossible de rattacher l'utilisateur ${usernameToLink} à l'organisation '${corporation?.name}'`)) ;
                    }
                    onClose() ;
                },
                onClose: async () => {
                    setConfirmModal(DefaultAsyncConfirmModalState) ;
                }
            })
        }
    } ;

    const renderCreateUserInfosPanel = () => {
        return isCorporateUserToShow ? <CorporationUserInfos maxWidth={maxWidth} visibility={visibleUserInfos ? "visible" : "hidden"} isAdminCorporate={connectedUserProfile?.userFlags?.profile === UserProfile.AdministratorCorporate} isCreation={isCreation} data={userToShow} actionButtons={actionButtons} onBack={() => setVisibleUserInfos(false)}  /> 
            : <UserInfos maxWidth={maxWidth} visibility={visibleUserInfos ? "visible" : "hidden"} data={userToShow} countries={countries} actionButtons={actionButtons} onBack={() => setVisibleUserInfos(false)}  />
    }

    useEffect(() => {
        setUserButtons() ;
    }, [isCorporateUserToShow, isAddedAdminCorporate]) ;

    useEffect(() => {
        const getCorporateUsers = async () => {
            await reloadCorporateUsers() ;
        } ;
    
        if ($ok(connectedUserProfile)) {
            if (!isAdminUser(connectedUserProfile?.userFlags?.profile)) {
                toast(defaultErrorToast("Vous n'êtes pas autorisé à accéder à cette page")) ;
                forceLogout(config.app.adminLoginURL!) ;
            }

            if (searchParams.get('view') === '1' && searchParams.get('corporationId') !== null) {
                const corporationId = $UUID(searchParams.get('corporationId')) ;
                setUserToShow({lastName: "", firstName: "", email: $email("")!, corporationId: $ok(corporationId) ? corporationId! : undefined }) ;
                setVisibleUserInfos(true) ;
                setIsCreation(true) ;
                setIsCorporateUserToShow(true) ;
                setIsAddedAdminCorporate(true) ;
            } else if (connectedUserProfile?.userFlags?.profile === UserProfile.AdministratorCorporate) {
                getCorporateUsers() ;
                setIsCorporateUserToShow(true) ;
            }
        }
    }, [connectedUserProfile]) ;

    return (
        <PageShellWithThreeColumns
            centralContent={
                <>
                    <ColumnTitle color={$g1Color('global.write')}>
                        <Text>Gestion des utilisateurs</Text>
                    </ColumnTitle>
                    <Box mb="50">
                        <Text>Recherche d'utilisateurs</Text>
                        <SearchUsers key={forceReload} maxWidth={maxWidth} displayIcon displayOrganizationName displayRole
                            isAdminCorporate={connectedUserProfile?.userFlags?.profile === UserProfile.AdministratorCorporate}
                            users={connectedUserProfile?.userFlags?.profile === UserProfile.AdministratorCorporate ? workedCorporateUsers : users}
                            onSearch={connectedUserProfile?.userFlags?.profile === UserProfile.AdministratorCorporate ? onSearchCorporateUser : onSearchUser}
                            onItemClick={onClickViewUser}
                        />
                    </Box>
                    <HStack justifyContent="center">
                        <G1Button component="form" variant="important" title={`Créer un ${connectedUserProfile?.userFlags?.profile === UserProfile.AdministratorCorporate ? 'collaborateur' : 'utilisateur'}`} marginRight="2%" fontSize="md" height="60px" onClick={e => onClickAddUser()} />
                    </HStack>
                </>
            }
            rightContent={
                <>
                { isCreation ? 
                    renderCreateUserInfosPanel()
                    : <Tabs isFitted marginTop="10" visibility={visibleUserInfos ? "visible" : "hidden"}>
                        <TabList color={$g1Color('global.tabs.write')} backgroundColor={$g1Color('global.tabs.bg')}>
                            <Tab fontWeight="semibold">Compte</Tab>
                            <Tab fontWeight="semibold">Consommations</Tab>
                        </TabList>
                        <TabIndicator color={$g1Color('global.tabs.indicator.write')} backgroundColor={$g1Color('global.tabs.indicator.bg')} />
                        <TabPanels>
                            <TabPanel>
                                {isCorporateUserToShow  ? 
                                    <CorporationUserInfos maxWidth={maxWidth} isAdminCorporate={true} isCreation={isCreation} data={userToShow} actionButtons={actionButtons} onBack={() => setVisibleUserInfos(false)}  />
                                    : <UserInfos maxWidth={maxWidth} data={userToShow} countries={countries} actionButtons={actionButtons} onBack={() => setVisibleUserInfos(false)}  />
                                }
                            </TabPanel>
                            <TabPanel>
                                <ConsumptionsBox nbAdvanced={nbAdvanced} nbQualified={nbQualified} consumptions={consumptions} onExport={onExportUserConsumptions} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                }
                <WaitingModal isOpen={isOpen} onClose={onClose} message="Rattachement en cours. Veuillez patienter..." />
                <AsyncLinkUserModal isOpen={isOpenLinkUserModal} items={corporations} onClose={async () => { setIsOpenLinkUserModal(false) ; }} onAccept={onLinkUserToCorporate} />
                <AsyncConfirmModal message={confirmModal.message} onAccept={confirmModal.onAccept} onClose={confirmModal.onClose} />
                </>    
            }
          isOpen={visibleUserInfos}
        />
    );
} ;

async function _toUserParameters(userId: UUID): Promise<UserParameters> {
    const userDto = await getUserProfile(userId) ;
    const corporation = userDto.corporations?.first() ;
    return {
        lastName: userDto.lastName,
        firstName: userDto.firstName,
        email: userDto.email,
        organizationName: $ok(corporation) ? corporation?.name : userDto.organizationName,
        organizationAddress: $ok(corporation) ? corporation?.address : userDto.organizationAddress,
        apid: userDto.apid,
        createdAt: userDto.createdAt,
        userFlags: userDto.userFlags,
        corporationId: corporation?.apid
    } ;
}