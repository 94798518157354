import { Flex } from "@chakra-ui/layout";
import { Box, ChakraProps } from "@chakra-ui/react";
import React from "react";

import { TSCountry } from "foundation-ts/tscountry";
import { CorporationDto } from "g1-commons/lib/doxecureClientTypes";

import { G1Icon } from "../../utils/icons";
import { CustomItemType } from "../../utils/TypesAndConstants";

import { CardDescription } from "./CardDescription";

interface CorporationCardAdminProps extends ChakraProps {
    item: CorporationDto;
    onItemClick: () => void ;
    itemStyle: CustomItemType;
}

export const CorporationCardAdmin = (props: CorporationCardAdminProps) => {
    const {
        itemStyle,
        item,
        onItemClick,
        ...rest
    } = props ;

    return (
        <Flex
            p="1"
            width="100%"
            minH="6em"
            borderTop={itemStyle.borderTop ?? "1px"}
            borderTopColor={itemStyle.borderTopColor ?? "inherit"}
            _hover={itemStyle.hover}
            _focus={itemStyle.select}
            cursor="pointer"
            onClick={onItemClick}
            {...rest}
        >
            <CardDescription width="100%" title={`${item.name} (${item.corporationIdentifier}) ${item.valid ? "" : "- Désactivé"}`} moreInfos={`${item.address.city} / ${TSCountry.country(item.address.country)?.name}`} />
            <Box alignSelf="center" width="20%" display="flex" justifyContent="flex-end">
                <Box margin="5%" alignSelf="center" color={itemStyle.iconColor ?? "inherit"}>
                    <G1Icon.View size="20px" />
                </Box>
            </Box>
        </Flex>
    ) ;
} ;