import { Box, FormControl, FormLabel, HStack, Heading, Image, Stack, StyleProps, Text, VStack, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";

import { $ok, $string } from "foundation-ts/commons";
import { UserDto } from "g1-commons/lib/doxecureClientTypes";

import { $g1Color, $g1IconURL } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { Link } from "../../components/Link/Link";
import { G1Button } from "../../components/G1Button";
import { G1Input } from "../../components/G1Input";
import { config } from "../../config/config";
import { checkAdminOTP, getUserUuid, sendAdminOTP } from "../../services/authentication.service";
import { forceLogout, isAdminUser } from "../../utils/functions";
import { defaultErrorToast, defaultInfoToast } from "../../utils/toast";

interface OtpViewProps extends StyleProps {
    connectedUserProfile?: UserDto ;
}

export const OtpView = ({ connectedUserProfile }: OtpViewProps) => {
    const [counter, setCounter] = useState(config.otp.expiresIn * 60) ;
    const [otp, setOtp] = useState('') ;
    const [isSubmitting, setSubmitting] = useState(false) ;
    const [isHiddenFormControl, setHiddenFormControl] = useState(false) ;
    const toast = useToast() ;

    useEffect(() => {
        if ($ok(connectedUserProfile) && !isAdminUser(connectedUserProfile?.userFlags?.profile)) {
            toast(defaultErrorToast("Vous n'êtes pas autorisé à accéder à cette page")) ;
            forceLogout(config.app.adminLoginURL!) ;
        }
    }, [connectedUserProfile]) ;
    
    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000) ;
        if (counter === 0) {
            setHiddenFormControl(true) ;
        }
    }, [counter]) ;

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setSubmitting(true) ;
            if (await checkAdminOTP(getUserUuid(), otp)) {
                setSubmitting(false) ;
                setOtp("") ;
                navigate("/admin/corporations") ;
            } else {
                setSubmitting(false) ;
                toast(defaultErrorToast("Code incorrect")) ;
            }
        } catch (e) {
            toast(defaultErrorToast((e as Error).message)) ;
            forceLogout(config.app.adminLoginURL!) ;
        }
    }

    const handleOtpChange = (event) => {
        const otp = $string(event.currentTarget.value).trim() ;
        setOtp(otp) ;
    };

    const onClickLink = async () => {
        try {
            if (await sendAdminOTP(getUserUuid())) {
                setCounter(config.otp.expiresIn * 60) ;
                setHiddenFormControl(false) ;
                toast(defaultInfoToast("Un nouveau code vous a été envoyé")) ;
            } else {
                toast(defaultErrorToast("Erreur lors de la génération d'un nouveau code")) ;
            }
        } catch (e) {
            toast(defaultErrorToast((e as Error).message)) ;
            forceLogout(config.app.adminLoginURL!) ;
        }
    }

    return (
        <Box py="5%">
            <Box color={$g1Color('connection.write')} bg={$g1Color('connection.logo.bg')} py="8" px="8" maxW="md" mx="auto" rounded={{ base: "0", md: "2xl" }}>
                <VStack spacing="4" align="stretch">
                    <Stack direction="row" alignItems="center" spacing="2">
                        <Image src={$g1IconURL('connection.logo.iconurl')} alt="Application logo" boxSize="72px" />
                        <Heading as="h1" textAlign="center" size="lg" fontWeight="bold">
                            {config.app.title}
                        </Heading>
                    </Stack>
                    <Text textAlign="center" fontWeight="bold" fontSize="20px">
                        Pour finaliser l'authentification, un code à usage unique vous a été envoyé par email.
                    </Text>
                    <Text textAlign="center" fontWeight="bold" fontSize="20px">
                        { (counter !== 0) ? `Il est valable ${counter} secondes...` : "" }
                    </Text>
                    <form  color={$g1Color('connection.form.write')} onSubmit={handleSubmit}>
                        <Stack spacing="6" bg={$g1Color('connection.form.bg')} py="8" px={{ base: "4", md: "10" }} shadow="base" rounded="lg" paddingBottom="1em">
                            <FormControl isRequired hidden={isHiddenFormControl}>
                                <FormLabel fontWeight="light">Code OTP</FormLabel>
                                <HStack spacing="2">
                                    <G1Input component="connection.form" type="number" name="otp" size="lg" onChange={ handleOtpChange } onBlur={ handleOtpChange } onInput={ handleOtpChange } />
                                </HStack>
                            </FormControl>
                            <Text textAlign="center" hidden={!isHiddenFormControl}>Temps écoulé - Un nouveau code est nécessaire</Text>
                            <Link
                                component="connection.form"
                                style={{ fontSize: "0.9rem", marginTop: "5px", textAlign: "center" }}
                                onClick={onClickLink}
                            >
                                Code non reçu ? Recevez-en un nouveau
                            </Link>
                            <G1Button component="connection" variant="ok" title="Valider" type="submit" size="lg" isLoading={ isSubmitting } disabled={ isSubmitting || (counter === 0)} />
                        </Stack>
                    </form>
                </VStack>
            </Box>
        </Box>
    ) ;
}