import { Box, Center, Flex, FormControl, FormLabel, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { $count, $email, $ok, $UUID } from "foundation-ts/commons";
import { UUID } from "foundation-ts/types";
import { CorporationDto } from "g1-commons/lib/doxecureClientTypes";
import { corporationIdentifiersDictionary } from "g1-commons/lib/doxecureLocales";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { useDevice } from "../../hooks/useDevice";
import { getCorporation } from "../../services/corporations.service";
import { userRoleAndStatus } from "../../utils/functions";
import { G1Icon } from "../../utils/icons";

import { ColumnTitle } from "../ColumnTitle/ColumnTitle";
import { ErrorField } from "../ErrorField" ;
import { G1Button } from "../G1Button";
import { G1Input } from "../G1Input";
import { SeaShellBox } from "../SeaShellBox" ;
import { UserInfosProperties, UserParameters } from "./UserInfos";

type CorporationUserInfosValues = {
    firstName: string,
    lastName: string,
    email: string,
    apid: string,
    corporationIdentifier: string
} ;

export const CorporationUserInfos = (props: UserInfosProperties) => {
    const { data, actionButtons, maxWidth, visibility, isAdminCorporate, isCreation, onBack } = props ;
    const { isMOBILE } = useDevice() ;
    const [isDisabledUserInfos, setIsDisabledUserInfos] = useState($ok(data)) ;
    const [corporation, setCorporation] = useState<CorporationDto|null>(null) ;
    const [mailDomains, setMailDomains] = useState<string[]|undefined>(undefined) ;
    const [mailPattern, setMailPattern] = useState<RegExp>(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) ;
    const {
        handleSubmit,
        register,
        reset,
        setValue,
        formState: { errors },
    } = useForm<any>({
        mode: "onChange",
        reValidateMode: "onChange",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValue(name, value) ;
    } ;

    const onSubmit: SubmitHandler<any> = (data: CorporationUserInfosValues, event) => {
        const dataUser: UserParameters = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: $email(data.email)!,
            corporationId: $UUID(corporation?.apid)!,
        } ;
        if ($ok(data.apid)) {
            dataUser.apid = $UUID(data.apid)! ;
        }

        event?.preventDefault() ;
        const button = (event?.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
        const buttonIndex = button.getAttribute('data-index');
        if ($ok(buttonIndex)) {
            actionButtons[buttonIndex!].onSubmit(dataUser) ;
        }
    } ;

    const renderCorporationInfos = () => {
        if (isAdminCorporate) {
            return <></> ;
        } else {
            return (
                <>
                    <FormControl isDisabled={isDisabledUserInfos || isCreation}>
                        <FormLabel fontSize="1.25em">Organisation</FormLabel>
                        <G1Input component="form" textTransform="uppercase" value={corporation?.name} />
                    </FormControl>
                    <FormControl isDisabled={isDisabledUserInfos || isCreation} marginTop={3}>
                        <FormLabel fontSize="1.25em">{`Identifiant "${$ok(corporation?.corporationIdentifierType) ? corporationIdentifiersDictionary[corporation!.corporationIdentifierType]?.name : ''}"`}</FormLabel>
                        <G1Input component="form" textTransform="uppercase" value={corporation?.corporationIdentifier} />
                    </FormControl>
                </>
            ) ;
        }
    }

    useEffect(() => {
        const getCorporationInfos = async (corporationId: UUID) => {
            const corporation = await getCorporation(corporationId) ;
            setCorporation(corporation) ;

            setMailDomains(corporation?.domains) ;
            if ($ok(corporation?.domains)) {
                const domainPattern = corporation?.domains?.map(domain => domain.replace(/\./g, '\\.')).join('|') ;
                setMailPattern(new RegExp(`^[a-zA-Z0-9._%+-]+@(${domainPattern})$`)) ;
            }
        }

        reset() ;
        setIsDisabledUserInfos($ok(data?.apid)) ;
        setValue("lastName", data?.lastName || "") ;
        setValue("firstName", data?.firstName || "") ;
        setValue("email", $ok(data?.email) ? data?.email.toString() : "") ;
        setValue("apid", data?.apid || null) ;

        if ($ok(data?.corporationId)) {
            getCorporationInfos(data?.corporationId!) ;
        }
    }, [data]) ;

    return (
      <>
        <ColumnTitle display={isMOBILE ? "block" : "none"} color={$g1Color('global.write')}>
            <Flex alignItems="center" as="button" onClick={onBack} width="100%">
                <Center color={$g1Color('global.icons.navigation.previous.write')}><G1Icon.Back /></Center>
                <Text>Retour</Text>
            </Flex>
        </ColumnTitle>
        {!isAdminCorporate && isCreation && 
            <ColumnTitle color={$g1Color('global.write')}>
                <Text>Création d'un gestionnaire</Text>
            </ColumnTitle>
        }
        <Box visibility={visibility} width="100%" mt={10} maxW={maxWidth}>
            <SeaShellBox component="form">
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    {renderCorporationInfos()}

                    <FormControl isRequired isDisabled={isDisabledUserInfos} marginTop={3}>
                        <FormLabel fontSize="1.25em">Nom</FormLabel>
                        <G1Input component="form" registerInput={{ ...register("lastName", { required: true }) }} textTransform="uppercase" onChange={handleChange} />
                        {errors.lastName && <ErrorField fieldName="nom" />}
                    </FormControl>

                    <FormControl isRequired isDisabled={isDisabledUserInfos} marginTop={3}>
                        <FormLabel fontSize="1.25em">Prénom</FormLabel>
                        <G1Input component="form" registerInput={{ ...register("firstName", { required: true }) }} textTransform="capitalize" onChange={handleChange} />
                        {errors.firstName && <ErrorField fieldName="prénom" />}
                    </FormControl>

                    <FormControl isRequired isDisabled={isDisabledUserInfos} marginTop={3}>
                        <FormLabel fontSize="1.25em">Adresse email</FormLabel>
                        <G1Input component="form" type="email" registerInput={{ ...register("email", { required: true, pattern: mailPattern }) }} textTransform="lowercase" autoComplete="true" onChange={handleChange} />
                        {($count(mailDomains) > 0) &&
                            <>
                                <Box as="span" fontSize="0.75em">Les domaines de mail uniquement acceptés sont : {mailDomains!.join(" - ")}</Box><br/>
                            </>
                        }
                        {errors.email && <ErrorField fieldName="email" error="Le champ email est requis ou est invalide" />}
                    </FormControl>

                    { isCreation ? <></> :
                        <>
                            <Text marginTop="20px" fontSize="md" fontWeight="bold" display="inline-block">
                                Profil:
                            </Text>
                            <Text marginTop="20px" marginLeft="5px" fontSize="md" display="inline-block">
                                { userRoleAndStatus(data?.userFlags!, isAdminCorporate) }
                            </Text>
                        </>
                    }

                    <FormControl>
                        <G1Input component="form" name="apid" type="hidden" />
                    </FormControl>

                    <Flex direction="row" justifyContent="center" marginTop="10px">
                        { actionButtons.length === 0 ? '' :
                                actionButtons.map((button, index) => (
                                    <G1Button component="form" variant={button.variant} type="submit" title={button.title} marginRight="2" fontSize="md" data-index={index} />
                                ))
                        }
                    </Flex>
                </form>
            </SeaShellBox>
        </Box>
      </>
    ) ;
} ;