import { Box, Center, Checkbox, Flex, FormControl, FormLabel, HStack, StyleProps, Text, useToast, VStack } from "@chakra-ui/react";
import React, { ChangeEvent, useEffect, useState } from "react" ;
import { SubmitHandler, useForm } from "react-hook-form";

import { $count, $keys, $ok } from "foundation-ts/commons";
import { TSCountry } from "foundation-ts/tscountry";
import { TSDate } from "foundation-ts/tsdate";
import { $country } from 'foundation-ts/tsdefaults';
import { Address, Countries, country, Nullable, UUID } from "foundation-ts/types";
import { CreateCorporationBody } from "g1-commons/lib/doxecureClientTypes";
import { corporationIdentifiersDictionary, G1Country } from "g1-commons/lib/doxecureLocales";
import { CorporationIdentifiersDictionary } from "g1-commons/lib/doxecureTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { useDevice } from "../../hooks/useDevice";
import apiClient from "../../services/apiClient";
import { G1Icon } from "../../utils/icons";
import { ActionButton } from "../../utils/TypesAndConstants";
import { defaultErrorToast, defaultWarningToast } from "../../utils/toast";

import { AddressForm } from "../AddressForm/AddressForm";
import { ColumnTitle } from "../ColumnTitle/ColumnTitle";
import { CorporationForm } from "../CorporationForm/CorporationForm";
import { AddDomainMailIcon, DeleteDomainMailIcon } from "../DomainMailIcons/DomainMailIcons";
import { G1Input } from "../G1Input";
import { G1Button } from "../G1Button";
import { SeaShellBox } from "../SeaShellBox";

export interface CorporationParameters extends CreateCorporationBody {
    apid?: UUID,
    createdAt?: TSDate,
    valid?: boolean,
} ;

type DomainInputType = {
    id: number,
    value: string
} ;

interface CorporationInfosProperties extends StyleProps {
    data: Nullable<CorporationParameters> ;
    actionButtons: ActionButton<CorporationParameters>[] ;
    countries?: G1Country[] ;
    availableCorporationIdentifiers?: Map<country, CorporationIdentifiersDictionary> ;
    disableDomainsList?: boolean ;
    disableFields?: boolean ;
    onBack?: () => void ;
} ;

export const CorporationInfos = (props: CorporationInfosProperties) => {
    const { data, actionButtons, visibility, maxWidth, disableDomainsList, disableFields, countries, availableCorporationIdentifiers, onBack } = props ;
    const [sameAddress, setSameAddress] = useState(true) ;
    const [domainInputs, setDomainInputs] = useState<DomainInputType[]>([]) ;
    const [addDomainInputId, setAddDomainInputId] = useState(Date.now()) ;
    const [newDomainInput, setNewDomainInput] = useState<DomainInputType|null>(null) ;
    const [oldBillingAddress, setOldBillingAddress] = useState<Nullable<Address>>(null) ;
    const [oldApiId, setOldApiId] = useState<Nullable<UUID>>(null) ;
    const [corporationIdentifiersDict, setCorporationIdentifiersDict] = useState<CorporationIdentifiersDictionary>({}) ;
    const { isMOBILE } = useDevice() ;
    const toast = useToast() ;
    const {
        getValues,
        handleSubmit,
        register,
        reset,
        setValue,
        formState: { errors },
    } = useForm<any>({
        mode: "onChange",
        reValidateMode: "onChange",
    }) ;

    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/;

    const handleAddInput = async () => {
        if ($ok(newDomainInput) && (newDomainInput!.value !== "")) {
            if (domainRegex.test(newDomainInput!.value) && (await apiClient.checkDomain(newDomainInput!.value))) {
                const newAddId = Date.now() ;
                setAddDomainInputId(newAddId) ;
                setDomainInputs([...domainInputs, { id: newDomainInput!.id, value: newDomainInput!.value }]) ;
                setNewDomainInput({ id: newAddId, value: "" }) ;
            } else {
                toast(defaultErrorToast("Nom de domaine invalide")) ;
            }
        }
    } ;
    
    const handleDeleteInput = (id: number) => {
        setDomainInputs(domainInputs.filter(input => input.id !== id)) ;
    } ;

    const handleCheckboxBillingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSameAddress(e.target.checked) ;
        // When viewing a corporation with a billingAddress different of its address and user check 'same address', we save the old billingAddress so we can show no specific billingAddress
        // And this allows that if user unchecks 'same address', we can show again the old billingAddress
        if (e.target.checked) {
            if ($ok(data?.billingAddress)) {
                setOldBillingAddress(data!.billingAddress) ;
                setOldApiId(data?.apid) ;
                data!.billingAddress = undefined ;
            }
        } else {
            if ($ok(data) && (data?.apid === oldApiId) && $ok(oldBillingAddress)) { // Display old billingAddress corresponding to the right corporate
                data!.billingAddress = oldBillingAddress! ;
            }
        }
    } ;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValue(name, value) ;
    }

    const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const newCountry = TSCountry.country(e.currentTarget.value)?.alpha2Code ;
        if ($ok(newCountry)) {
            const newDict = availableCorporationIdentifiers?.get(newCountry!) ;
            if ($ok(newDict)) { 
                setCorporationIdentifiersDict(newDict!) ;
                setValue("organizationIdentifierType", $keys(newDict).first()) ;
            }
        }
        setValue("organizationCountry", e.currentTarget.value) ;
    }

    const onSubmit: SubmitHandler<any> = (data: any, event) => {
        if ($count(domainInputs) === 0) {
            toast(defaultWarningToast("Vous devez définir au minimum un nom de domaine de mail")) ;
            return ;
        }

        const corporationParams: CorporationParameters = {
            apid: data.apid || undefined,
            name: data.organizationName,
            corporationIdentifierType: data.organizationIdentifierType,
            corporationIdentifier: data.organizationIdentifier,
            address: {
                streetNumber: $ok(data.streetNumber) && (data.streetNumber.length > 0) ? data.streetNumber : undefined,
                street: $ok(data.street) && (data.street.length > 0) ? data.street : undefined,
                complement: $ok(data.streetComplement) && (data.streetComplement.length > 0) ? data.streetComplement : undefined,
                zipCode: $ok(data.zipCode) && (data.zipCode.length > 0) ? data.zipCode : undefined,
                city: data.city.toUpperCase(),
                country: $ok(data.organizationCountry) ? $country(data.organizationCountry)! : Countries.FR
            }
        } ;
        if (data.organizationIdentifierType === "VAT" || ($ok(data.vatNumber) && (data.vatNumber.length > 0))) {
            corporationParams.vatNumber = data.organizationIdentifierType === "VAT" ? data.organizationIdentifier : data.vatNumber ;
        }
        if (sameAddress) {
            corporationParams.billingAddress = undefined ;
        } else if ($ok(data["billing-city"]) && (data["billing-city"].length > 0)) {
            corporationParams.billingAddress = {
                streetNumber: $ok(data["billing-streetNumber"]) && (data["billing-streetNumber"].length > 0) ? data["billing-streetNumber"] : undefined,
                street: $ok(data["billing-street"]) && (data["billing-street"].length > 0) ? data["billing-street"] : undefined,
                complement: $ok(data["billing-streetComplement"]) && (data["billing-streetComplement"].length > 0) ? data["billing-streetComplement"] : undefined,
                zipCode: $ok(data["billing-zipCode"]) && (data["billing-zipCode"].length > 0) ? data["billing-zipCode"] : undefined,
                city: data["billing-city"].toUpperCase(),
                country: $ok(data["billing-country"]) ? $country(data["billing-country"])! : Countries.FR
            } ;
        }
        corporationParams.domains = [] ;
        for (let input of domainInputs) {
            corporationParams.domains!.push(input.value.toLowerCase()) ;
        }

        const button = (event?.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
        const buttonIndex = button.getAttribute('data-index');
        if ($ok(buttonIndex)) {
            actionButtons[buttonIndex!].onSubmit(corporationParams) ;
        }
    } ;

    const validateCorporationIdentifier = (id: string): boolean => {
        const selectedCountry = getValues("organizationCountry") ;
        const selectedCorporationIdentifierType = getValues("organizationIdentifierType") ;
        return corporationIdentifiersDictionary[selectedCorporationIdentifierType!]!.validate(id, selectedCountry) ;
    }

    const renderDomainMailItem = (id: number, value: string) => {
        return (
            <HStack spacing={4}>
                <G1Input component="form" placeholder="ex: mail.fr" disabled={true} textTransform="lowercase" value={value} />
                {!disableDomainsList && <DeleteDomainMailIcon size="2em" onClick={() => handleDeleteInput(id)} />}
            </HStack>
        ) ;
    } ;

    const renderAddDomainMailItem = (id: number) => {
        return (
            <HStack spacing={4}>
                <G1Input component="form" placeholder="ex: mail.fr" textTransform="lowercase" value={newDomainInput?.value} onChange={(e) => setNewDomainInput({ id: id, value: e.target.value })} />
                <AddDomainMailIcon size="2em" onClick={() => handleAddInput()} />
            </HStack>
        ) ;
    } ;

    useEffect(() => {
        reset() ;
        const newCountry = TSCountry.country(data?.address?.country || Countries.FR)?.alpha2Code ;
        if ($ok(newCountry)) {
            const newDict = availableCorporationIdentifiers?.get(newCountry!) ;
            if ($ok(newDict)) { 
                setCorporationIdentifiersDict(newDict!) ;
            }
        }

        setSameAddress(!$ok(data?.billingAddress)) ;
        setValue("organizationName", data?.name || "") ;
        setValue("organizationCountry", data?.address.country) ;
        setValue("organizationIdentifier", data?.corporationIdentifier || "") ;
        setValue("organizationIdentifierType", data?.corporationIdentifierType) ;
        setValue("apid", data?.apid) ;
        setValue("streetNumber", data?.address?.streetNumber || "") ;
        setValue("street", data?.address?.street || "") ;
        setValue("streetComplement", data?.address?.complement || "") ;
        setValue("zipCode", data?.address?.zipCode || "") ;
        setValue("city", data?.address?.city || "") ;
        if ($ok(data?.billingAddress)) {
            setValue("billing-streetNumber", data?.billingAddress?.streetNumber || "") ;
            setValue("billing-street", data?.billingAddress?.street || "") ;
            setValue("billing-streetComplement", data?.billingAddress?.complement || "") ;
            setValue("billing-zipCode", data?.billingAddress?.zipCode || "") ;
            setValue("billing-city", data?.billingAddress?.city || "") ;
            setValue("billing-country", data?.billingAddress?.country || data?.address?.country || Countries.FR) ;
        }

        setDomainInputs($ok(data) && $ok(data!.domains) && (data!.domains!.length > 0) ? data!.domains!.map(data => ({ id: Date.now() + Math.random(), value: data })) : []) ;
    }, [data, availableCorporationIdentifiers]) ;

    return (
        <>
            {onBack &&
                <ColumnTitle display={isMOBILE ? "block" : "none"} color={$g1Color('global.write')}>
                    <Flex alignItems="center" as="button" onClick={onBack} width="100%">
                        <Center color={$g1Color('global.icons.navigation.previous.write')}><G1Icon.Back /></Center>
                        <Text>Retour</Text>
                    </Flex>
                </ColumnTitle>
            }
            <Box visibility={visibility} width="100%" mt={10} maxW={maxWidth}>
                <SeaShellBox component="form">
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <CorporationForm label="Nom de l'organisation" required={true} disabled={disableFields} showUniqueId={true}
                            countries={countries}
                            corporationIdentifiersDict={corporationIdentifiersDict}
                            selectedCIT={data?.corporationIdentifierType}
                            errors={errors}
                            register={register}
                            onChange={handleChange}
                            onCountryChange={handleCountryChange}
                            validate={validateCorporationIdentifier} />

                        <Box borderWidth="1px" rounded={{ base: "0", md: "md" }} borderColor="grey" padding={2} marginTop="20px !important">
                            <Box display="flex" alignItems="center" position="relative" top="-22px" width={"fit-content"} paddingX="1" bg={$g1Color('form.local.bg')}>
                                <Checkbox
                                    id="cbBilling"
                                    name="cbBilling"
                                    isChecked={sameAddress}
                                    disabled={disableFields}
                                    bg={$g1Color('form.field.bg')}
                                    color={$g1Color('form.field.write')}
                                    borderColor={$g1Color('form.field.border')}
                                    _hover={{ borderColor: $g1Color('form.field.hover.border') }}
                                    _focus={{ borderColor: $g1Color('form.field.select.border') }}
                                    onChange={handleCheckboxBillingChange} />
                                <span style={{ marginLeft: '5px' }}>Adresse de facturation identique à l'adresse de l'organisation ?</span>
                            </Box>
                            {!sameAddress && <AddressForm prepId="billing-" required={!sameAddress} disabled={sameAddress} firstPosition={true} countries={countries} errors={errors} register={register} onChange={handleChange} />}
                        </Box>

                        <FormControl marginTop={3}>
                            <FormLabel fontSize="1.25em">Liste des noms de domaine autorisés dans les courriels</FormLabel>
                            <VStack alignItems="normal">
                                { domainInputs.map((input) => renderDomainMailItem(input.id, input.value)) }
                                { !disableDomainsList && renderAddDomainMailItem(addDomainInputId) }
                            </VStack>
                        </FormControl>

                        <FormControl>
                            <G1Input component="form" name="apid" type="hidden" />
                        </FormControl>

                        <Flex direction="row" justifyContent="center" marginTop={3}>
                            { actionButtons.length === 0 ? '' :
                                actionButtons.map((button, index) => (
                                    <G1Button type="submit" key={index} component="form" variant={button.variant} title={button.title} marginRight="2" fontSize="md" data-index={index} />
                                ))
                            }
                            </Flex>
                    </form>
                </SeaShellBox>
            </Box>
        </>
    ) ;
} ;