import { navigate, useLocation } from "@reach/router";

import { HStack, StyleProps, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { $ok, $UUID } from "foundation-ts/commons";
import { TSCountry } from "foundation-ts/tscountry";
import { country, Nullable, UUID } from "foundation-ts/types";
import { Resp } from "foundation-ts/tsrequest";
import { CorporationDto, UserDto } from "g1-commons/lib/doxecureClientTypes";
import { ConsumptionsStructure, CorporationIdentifiersDictionary, UserProfile } from "g1-commons/lib/doxecureTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { SearchCorporations } from "../../components/admin/SearchCorporations";
import { ColumnTitle } from "../../components/ColumnTitle/ColumnTitle";
import { CorporationInfos, CorporationParameters } from "../../components/admin/CorporationInfos";
import { SearchUsers } from "../../components/admin/SearchUsers";
import { ConsumptionsBox } from "../../components/ConsumptionsBox/ConsumptionsBox";
import { AsyncConfirmModal, DefaultAsyncConfirmModalState } from "../../components/Modals/AsyncConfirmModal";
import { PageShellWithThreeColumns } from "../../components/PageShellWithThreeColumns/PageShellWithThreeColumns";
import { UserParameters } from "../../components/UserInfos/UserInfos";
import { G1Button } from "../../components/G1Button";

import { config } from "../../config/config";
import useCorporations from "../../hooks/useCorporations";
import useCountries from "../../hooks/useCountries";
import useCreateCorporation from "../../hooks/useCreateCorporation";
import apiClient from "../../services/apiClient";
import { deactivateCorporation, exportCorporationConsumptions, getCorporation, getCorporationConsumptions, getCorporationCurrentConsumption, getCorporationUsers, reactivateCorporation, requestAddDomains, updateCorporation } from "../../services/corporations.service";
import { ActionButton } from "../../utils/TypesAndConstants";
import { forceLogout, isAdminUser } from "../../utils/functions";
import { defaultErrorToast, defaultInfoToast, defaultSuccessToast } from "../../utils/toast";

interface CorporationsViewProps extends StyleProps {
    connectedUserProfile?: UserDto ;
}

export const CorporationsView = ({ maxWidth, connectedUserProfile }: CorporationsViewProps) => {
    const { mutateAsync: createCorporation } = useCreateCorporation() ;
    const { data: corporations } = useCorporations() ;
    const { data: countries } = useCountries() ;
    const [availableCorporationIdentifiers, setAvailableCorporationIdentifiers] = useState<Map<country, CorporationIdentifiersDictionary>>(new Map()) ;
    const [workedCorporationsList, setWorkedCorporationsList] = useState<CorporationDto[]>([]) ;
    const [corporationToShow, setCorporationToShow] = useState<Nullable<CorporationParameters>>(null) ;
    const [corporationUsers, setCorporationUsers] = useState<UserParameters[]>([]) ;
    const [workedCorporationUsersList, setWorkedCorporationUsersList] = useState<UserParameters[]>([]) ;
    const [actionButtons, setActionButtons] = useState<ActionButton<CorporationParameters>[]>([]) ;
    const [confirmModal, setConfirmModal] = useState(DefaultAsyncConfirmModalState) ;
    const [visibleInfos, setVisibleInfos] = useState(false) ;
    const [disableDomainsList, setDisableDomainsList] = useState(false) ;
    const [disableFields, setDisableFields] = useState(false) ;
    const [nbAdvanced, setNbAdvanced] = useState(0) ;
    const [nbQualified, setNbQualified] = useState(0) ;
    const [consumptions, setConsumptions] = useState<Nullable<ConsumptionsStructure>>(null) ;
    const [tabIndex, setTabIndex] = useState(0);
    const toast = useToast() ;

    const location = useLocation() ;
    const searchParams = new URLSearchParams(location.search) ;

    const handleSearch = async (value?: Nullable<string>) => {
        setCorporationToShow(null) ;
        setVisibleInfos(false) ;
        if ($ok(corporations)) {
            setWorkedCorporationsList($ok(value) ? corporations!.filter(corporation => corporation.name.includes(value!.toUpperCase()) || corporation.corporationIdentifier.startsWith(value!.toUpperCase())) : corporations!) ;
        }
    }

    const handleView = async (corporationId: UUID, tabIndex: number = 0) => {
        try {
            const filteredCorporations = corporations?.filter(corporation => corporation.apid === corporationId) ;
            const corporation = $ok(filteredCorporations) && (filteredCorporations!.length > 0)  ? filteredCorporations!.first() : await getCorporation(corporationId) ;
            if ($ok(corporation)) {
                setVisibleInfos(true) ;
                setDisableDomainsList(!corporation!.valid) ;
                setDisableFields(!corporation!.valid) ;
                setCorporationToShow({
                    apid: corporation!.apid,
                    name: corporation!.name,
                    corporationIdentifierType: corporation!.corporationIdentifierType,
                    corporationIdentifier: corporation!.corporationIdentifier,
                    address: corporation!.address,
                    billingAddress: corporation?.billingAddress,
                    domains: corporation?.domains,
                }) ;

                const users = await getCorporationUsers(corporation!.apid) ;
                setCorporationUsers($ok(users) ? users! : []) ;
                setWorkedCorporationUsersList($ok(users) ? users! : []) ;

                const currentConsumption = await getCorporationCurrentConsumption(corporation!.apid) ;
                setNbAdvanced($ok(currentConsumption) ? currentConsumption!.advanced : -1) ;
                setNbQualified($ok(currentConsumption) ? currentConsumption!.qualified : -1) ;
                setConsumptions(await getCorporationConsumptions(corporation!.apid)) ;

                if (corporation?.valid) {
                    setActionButtons([{ title: "Mettre à jour", variant: "important", onSubmit: onSubmitUpdateCorporation }, { title: "Désactiver", variant: "important", onSubmit: onSubmitDeactivateCorporation }]) ;
                } else {
                    setActionButtons([{ title: "Réactiver", variant: "important", onSubmit: onSubmitReactivateCorporation }]) ;
                }
                setTabIndex(tabIndex) ;
                if (tabIndex === 0 && searchParams.get("corporationId") !== null) { // Remove url param if exists when click on another corporation after created a new admin corporate
                    const url = new URL(window.location.href) ;
                    url.searchParams.delete("corporationId") ;
                    window.history.replaceState({}, '', url.toString()) ;
                } 
            } else {
                toast(defaultErrorToast("Impossible de récupérer les données de cette organisation")) ;
            }
        } catch (e) {
            if ((e as Error).message === Resp.Unauthorized.toString()) {
                toast(defaultErrorToast("Erreur lors de la récupération des données de l'organisation. Soit vous avez dépassé le délai d'inactivité sur l'interface, soit vous n'êtes pas autorisé à y accéder. Vous avez été automatiquement déconnecté, veuillez-vous authentifier à nouveau.")) ;
                forceLogout(config.app.adminLoginURL!) ;
            } else {
                toast(defaultErrorToast((e as Error).message)) ;
            }
        }
    }

    const handleAddCorporation = () => {
        setCorporationToShow(null) ;
        setVisibleInfos(true) ;
        setActionButtons([{ title: "Créer", variant: "important", onSubmit: onSubmitAddCorporation }]) ;
    }

    const onExportConsumptions = async () => {
        if ($ok(corporationToShow)) {
            try {
                await exportCorporationConsumptions(corporationToShow!.apid!, corporationToShow!.name) ;
            } catch (e) {
                if ((e as Error).message === Resp.Unauthorized.toString()) {
                    toast(defaultErrorToast("Erreur lors de l'export des données. Soit vous avez dépassé le délai d'inactivité sur l'interface, soit vous n'êtes pas autorisé à y accéder. Vous avez été automatiquement déconnecté, veuillez-vous authentifier à nouveau.")) ;
                    forceLogout(config.app.adminLoginURL!) ;
                } else {
                    toast(defaultErrorToast("Problème rencontré lors de l'export des données")) ;
                }
            }
        }
    } ;

    const onSearchCorporationUser = async (search?: Nullable<string>): Promise<void> => {
        if ($ok(corporationUsers)) {
            setWorkedCorporationUsersList($ok(search) ? corporationUsers.filter(user => user.email === search || user.lastName.includes(search!.toUpperCase())) : corporationUsers) ;
        }
    }

    const onSubmitAddCorporation = async (data: CorporationParameters) => {
        try {
            const newCorporationDto = await createCorporation({ name: data.name.toUpperCase(), corporationIdentifierType: data.corporationIdentifierType, corporationIdentifier: data.corporationIdentifier, vatNumber: data.vatNumber, address: data.address, billingAddress: data.billingAddress, domains: data.domains }) ;
            if ($ok(newCorporationDto)) {
                toast(defaultInfoToast(`L'organisation '${data.name.toUpperCase()}' a été créé. Vous pouvez désormais lui ajouter des utilisateurs.`)) ;
                setWorkedCorporationsList([...workedCorporationsList, newCorporationDto].sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))) ;
                setCorporationToShow(null) ;
                setVisibleInfos(false) ;
            } else {
                toast(defaultInfoToast(`Erreur lors de la création`)) ;
            }
        } catch (e) {
            if ((e as Error).message === Resp.Unauthorized.toString()) {
                toast(defaultErrorToast("Erreur lors de l'ajout d'une organisation. Soit vous avez dépassé le délai d'inactivité sur l'interface, soit vous n'êtes pas autorisé à y accéder. Vous avez été automatiquement déconnecté, veuillez-vous authentifier à nouveau.")) ;
                forceLogout(config.app.adminLoginURL!) ;
            } else {
                toast(defaultErrorToast((e as Error).message === Resp.Conflict.toString() ? "Une organisation avec cet identifiant national existe déjà" : "Erreur lors de la création de l'organisation")) ;
            }
        }
    }

    const onSubmitUpdateCorporation = async (data: CorporationParameters) => {
        try {
            const updatedCorporationDto = await updateCorporation(data.apid!, {name: data.name, corporationIdentifierType: data.corporationIdentifierType, corporationIdentifier: data.corporationIdentifier, address: data.address, billingAddress: data.billingAddress, domains: data.domains}) ;
            if ($ok(updatedCorporationDto)) {
                toast(defaultInfoToast(`Les données de l'organisation '${data.name.toUpperCase()}' ont été mises à jour`)) ;
                const index = workedCorporationsList.findIndex(corporation => corporation.apid === data.apid) ;
                if (index !== -1) {
                    setWorkedCorporationsList([]) ;
                    workedCorporationsList.splice(index, 1, updatedCorporationDto) ;
                    setWorkedCorporationsList(workedCorporationsList) ;
                }
            } else {
                toast(defaultInfoToast(`Erreur lors de la mise à jour`)) ;
            }
        } catch (e) {
            if ((e as Error).message === Resp.Unauthorized.toString()) {
                toast(defaultErrorToast("Erreur lors de la mise à jour d'une organisation. Soit vous avez dépassé le délai d'inactivité sur l'interface, soit vous n'êtes pas autorisé à y accéder. Vous avez été automatiquement déconnecté, veuillez-vous authentifier à nouveau.")) ;
                forceLogout(config.app.adminLoginURL!) ;
            } else {
                toast(defaultErrorToast((e as Error).message === Resp.Conflict.toString() ? "Une organisation avec cet identifiant national existe déjà" : "Erreur lors de la mise à jour de l'organisation")) ;
            }
        }
    }

    const onSubmitDeactivateCorporation = async (data: CorporationParameters) => {
        setConfirmModal({
            title: "Confirmation",
            message: `Etes-vous sûr de vouloir désactiver l'organisation ${data.name}`,
            onAccept: async () => {
                try {
                    if (await deactivateCorporation(data.apid!)) {
                        toast(defaultSuccessToast("Cette organisation a été désactivée. Plus aucun collaborateur ni gestionnaire ne pourront s'authentifier.")) ;
                        setDisableFields(true) ;
                        setDisableDomainsList(true) ;
                        setActionButtons([{ title: "Réactiver", variant: "important", onSubmit: onSubmitReactivateCorporation }]) ;
                        const modifiedCorpo = corporations?.filter(corporation => corporation.apid === data.apid).first() ;
                        if ($ok(modifiedCorpo)) {
                            modifiedCorpo!.valid = false ;
                            setWorkedCorporationsList(corporations!) ;
                        }
                    } else {
                        toast(defaultInfoToast(`Impossible de désactiver cette organisation`)) ;
                    }
                } catch (e) {
                    if ((e as Error).message === Resp.Unauthorized.toString()) {
                        toast(defaultErrorToast("Erreur lors de la désactivation d'une organisation. Soit vous avez dépassé le délai d'inactivité sur l'interface, soit vous n'êtes pas autorisé à y accéder. Vous avez été automatiquement déconnecté, veuillez-vous authentifier à nouveau.")) ;
                        forceLogout(config.app.adminLoginURL!) ;
                    } else {
                        toast(defaultErrorToast("Erreur lors de la désactivation de cette organisation")) ;
                    }
                }
                setConfirmModal(DefaultAsyncConfirmModalState) ;
            },
            onClose: async () => {
                setConfirmModal(DefaultAsyncConfirmModalState) ;
            }
        });
    }

    const onSubmitReactivateCorporation = async (data: CorporationParameters) => {
        setConfirmModal({
            title: "Confirmation",
            message: `Etes-vous sûr de vouloir réactiver l'organisation ${data.name}`,
            onAccept: async () => {
                try {
                    if (await reactivateCorporation(data.apid!)) {
                        toast(defaultSuccessToast("Cette organisation a été réactivée. Les collaborateurs et les gestionnaires peuvent, à nouveau, s'authentifier sur la plateforme.")) ;
                        setDisableFields(false) ;
                        setDisableDomainsList(false) ;
                        setActionButtons([{ title: "Mettre à jour", variant: "important", onSubmit: onSubmitUpdateCorporation }, { title: "Désactiver", variant: "important", onSubmit: onSubmitDeactivateCorporation }]) ;
                        const modifiedCorpo = corporations?.filter(corporation => corporation.apid === data.apid).first() ;
                        if ($ok(modifiedCorpo)) {
                            modifiedCorpo!.valid = true ;
                            setWorkedCorporationsList(corporations!) ;
                        }
                    } else {
                        toast(defaultInfoToast(`Impossible de réactiver cette organisation`)) ;
                    }
                } catch (e) {
                    if ((e as Error).message === Resp.Unauthorized.toString()) {
                        toast(defaultErrorToast("Erreur lors de la réactivation de cette organisation. Soit vous avez dépassé le délai d'inactivité sur l'interface, soit vous n'êtes pas autorisé à y accéder. Vous avez été automatiquement déconnecté, veuillez-vous authentifier à nouveau.")) ;
                        forceLogout(config.app.adminLoginURL!) ;
                    } else {
                        toast(defaultErrorToast("Erreur lors de la réactivation de cette organisation")) ;
                    }
                }
                setConfirmModal(DefaultAsyncConfirmModalState) ;
            },
            onClose: async () => {
                setConfirmModal(DefaultAsyncConfirmModalState) ;
            }
        });
    }

    const onRequestAddDomainsToSupport = async (_unused: CorporationParameters) => {
        if (await requestAddDomains(workedCorporationsList.first()!.apid)) {
            toast(defaultInfoToast("Un mail a été envoyé au support. Celui-ci vous contactera au plus vite")) ;
        } else {
            toast(defaultErrorToast("Une erreur s'est produite lors de l'envoi de votre demande de support")) ;
        }
    }

    const onCreateAdminCorporate = async () => {
        navigate(`/admin/users?view=1&corporationId=${corporationToShow?.apid}`) ;
    }

    useEffect(() => {
        if ($ok(connectedUserProfile)) {
            if (!isAdminUser(connectedUserProfile?.userFlags?.profile)) {
                toast(defaultErrorToast("Vous n'êtes pas autorisé à accéder à cette page")) ;
                forceLogout(config.app.adminLoginURL!) ;
            }

            if (connectedUserProfile?.userFlags?.profile === UserProfile.AdministratorCorporate) {
                setCorporationToShow(connectedUserProfile.corporations?.first()) ;
                setActionButtons([{ title: "Mettre à jour", variant: "important", onSubmit: onSubmitUpdateCorporation }, { title: "Demande d'ajout de domaines", variant: "normal", onSubmit: onRequestAddDomainsToSupport }]) ;
                setDisableDomainsList(true) ;
            } else  if (searchParams.get('corporationId') !== null) {
                const corporationId = $UUID(searchParams.get('corporationId')) ;
                if ($ok(corporationId)) { 
                    handleView(corporationId!, 2) ;
                }
            }
        }
    }, [connectedUserProfile]) ;

    useEffect(() => {
        if ($ok(corporations)) {
            setWorkedCorporationsList(corporations!) ;
        }
    }, [corporations]) ;

    useEffect(() => {
        const getCorporationIdentifiers = async () => {
            const map: Map<country, CorporationIdentifiersDictionary> = new Map() ;
            for (let g1Country of countries!) {
                const country = TSCountry.country(g1Country.code)!.alpha2Code ;
                const [corporationIdentifiersDict, _status] = await apiClient.getAvailableCorporationIdentifiers(country) ;
                if ($ok(corporationIdentifiersDict)) {
                    map.set(country, corporationIdentifiersDict!) ;
                }
            }
            setAvailableCorporationIdentifiers(map) ;
        }
        if ($ok(countries)) {
            getCorporationIdentifiers() ;
        }
    }, [countries]) ;

    return (
        <>
            {connectedUserProfile?.userFlags?.profile === UserProfile.AdministratorCorporate ? 
            <PageShellWithThreeColumns
                centralContent={
                    <>
                        <ColumnTitle color={$g1Color('global.write')}>
                            <Text>Gestion de l'organisation</Text>
                        </ColumnTitle>
                        <CorporationInfos data={corporationToShow} maxWidth={maxWidth} disableDomainsList={disableDomainsList} disableFields={disableFields} countries={countries} availableCorporationIdentifiers={availableCorporationIdentifiers} actionButtons={actionButtons} />
                    </>
                }
                rightContent={<></>}
            />
            :
            <PageShellWithThreeColumns
                centralContent={
                    <>
                        <ColumnTitle color={$g1Color('global.write')}>
                            <Text>Gestion des organisations</Text>
                        </ColumnTitle>
                        <SearchCorporations maxWidth={maxWidth} corporations={workedCorporationsList} onSearch={handleSearch} onView={handleView} />
                        <HStack justifyContent="space-around">
                            {(connectedUserProfile?.userFlags?.profile === UserProfile.Root || connectedUserProfile?.userFlags?.profile === UserProfile.Administrator) && <G1Button component="form" variant="important" title="Créer une organisation" marginRight="2%" fontSize="md" height="60px" onClick={handleAddCorporation} /> }
                        </HStack>
                    </>
                }
                rightContent={
                    <>
                        { !$ok(corporationToShow) ? 
                            <CorporationInfos maxWidth={maxWidth} visibility={visibleInfos ? "visible" : "hidden"} data={corporationToShow} availableCorporationIdentifiers={availableCorporationIdentifiers} countries={countries} actionButtons={actionButtons} onBack={() => setVisibleInfos(false)} />
                            : <Tabs isFitted marginTop="10" index={tabIndex} onChange={(index) => setTabIndex(index)}>
                                <TabList color={$g1Color('global.tabs.write')} backgroundColor={$g1Color('global.tabs.bg')}>
                                    <Tab fontWeight="semibold">Informations</Tab>
                                    <Tab fontWeight="semibold">Consommations</Tab>
                                    <Tab fontWeight="semibold">Utilisateurs</Tab>
                                </TabList>
                                <TabIndicator color={$g1Color('global.tabs.indicator.write')} backgroundColor={$g1Color('global.tabs.indicator.bg')} />
                                <TabPanels>
                                    <TabPanel>
                                        <CorporationInfos data={corporationToShow} maxWidth={maxWidth} visibility={visibleInfos ? "visible" : "hidden"} disableDomainsList={disableDomainsList} disableFields={disableFields} countries={countries} availableCorporationIdentifiers={availableCorporationIdentifiers} actionButtons={actionButtons} onBack={() => setVisibleInfos(false)} />
                                    </TabPanel>
                                    <TabPanel>
                                        <ConsumptionsBox nbAdvanced={nbAdvanced} nbQualified={nbQualified} consumptions={consumptions} onExport={onExportConsumptions} />
                                    </TabPanel>
                                    <TabPanel>
                                        <SearchUsers maxWidth={maxWidth} displayRole isAdminCorporate={connectedUserProfile?.userFlags?.profile === UserProfile.AdministratorCorporate} users={workedCorporationUsersList} onSearch={onSearchCorporationUser} />
                                        <HStack justifyContent="space-around" mt={3}>
                                            <G1Button component="form" variant="important" title="Créer un gestionnaire" marginRight="2%" fontSize="md" height="60px" disabled={disableFields} onClick={e => onCreateAdminCorporate()} /> 
                                        </HStack>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        }
                    </>
                }
                isOpen={visibleInfos}
            />}
            <AsyncConfirmModal title={confirmModal.title} message={confirmModal.message} onAccept={confirmModal.onAccept} onClose={confirmModal.onClose} />
        </>
    );
} ;