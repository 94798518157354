import { Box, StyleProps, Text, VStack } from "@chakra-ui/react" ;
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react" ;

import { $count, $length } from "foundation-ts/commons";
import { $ascii, $trim } from "foundation-ts/strings";
import { Nullable, UUID } from "foundation-ts/types";
import { CorporationDto } from "g1-commons/lib/doxecureClientTypes";
import { compareCorporationBodies } from "g1-commons/lib/doxecureFunctions";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { CustomItemType } from "../../utils/TypesAndConstants";

import { CorporationCardAdmin } from "../Cards/CorporationCardAdmin";
import { SearchField } from "../SearchField/SearchField";
import { SectionBox } from "../SectionBox/SectionBox";
import { SectionOrganizationNames } from "../../utils/ReactTypesAndConstants";

export interface SearchCorporationsProperties extends StyleProps {
    corporations: CorporationDto[] ;
    onSearch: (search?: Nullable<string>) => Promise<void> ;
    onView: (userId: UUID) => void ;
} ;

export const SearchCorporations = ({corporations, onSearch, onView}: SearchCorporationsProperties) => {
    const [searchValue, setSearchValue] = useState<string>("") ;
    const searchFormRef = useRef<HTMLFormElement>(null) ;
    const corporationsSections = $count(corporations) ? _createSectionList(corporations).sort(_compareSection) : [] ;

    const handleSearch = async (e: FormEvent) => {
        e.preventDefault();
        if (e.type === "submit") {
            if ($length(e.target[0].value) === 0) {
                await onSearch() ;
            } else {
                await onSearch(e.target[0].value) ;
            }
        }
    }

    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value) ;
    } ;

    const renderCorporationBox = (item: CorporationDto) => {
        return <CorporationCardAdmin key={item.apid} item={item} itemStyle={itemCorporationStyle} onItemClick={() => onView(item.apid!)} /> ;
    }

    useEffect(() => {
        if (searchValue.length === 0) {
            searchFormRef.current?.requestSubmit() ;
        }
    }, [searchValue]) ;

    return (
        <Box mb="50">
            <Box mb="6">
                <form ref={searchFormRef} onSubmit={handleSearch}>
                    <SearchField component="form" h="30px" placeHolder="Nom ou identifiant national de l'organisation" onChange={handleSearchInputChange} />
                </form>
            </Box>
            <VStack align="stretch" spacing="5">
            {corporationsSections.map((section, index) => {
                return (
                    <Box width="100%" key={index}>
                        <Text fontWeight="bold" marginBottom="5%">{section.letter}</Text>
                        <SectionBox items={section.items} sectionStyle={corporationsSectionStyle} renderItem={renderCorporationBox} />
                    </Box>
                );
            })}
            </VStack>
        </Box>
    ) ;
} ;

const corporationsSectionStyle:StyleProps = {
    bg: $g1Color('list.corporations.bg'),
    color: $g1Color('list.corporations.write'),
    fontWeight: "bold",
    overflow: "hidden",
    rounded: "xl",
    width: "100%",
}

const itemCorporationStyle: CustomItemType = {
    borderTop: "1px",
    borderTopColor: $g1Color('list.spacer'),
    iconColor: $g1Color('list.icon'),
    hover: { bg: $g1Color('list.corporations.hover.bg'), color: $g1Color('list.corporations.hover.write') },
    select: { bg: $g1Color('list.corporations.select.bg'), color: $g1Color('list.corporations.select.write') },
}

const _compareCorporations = (a: CorporationDto, b: CorporationDto) => {
    return compareCorporationBodies(a, b) ;
};

const _compareSection = (a: SectionOrganizationNames, b: SectionOrganizationNames) => {
    if (a.letter < b.letter) {
        return -1 ;
    } else if (a.letter === b.letter) {
        return 0 ;
    } else {
        return 1 ;
    }
} ;

const _createSectionList = (corporations:CorporationDto[]): SectionOrganizationNames[] => {
    const sections:SectionOrganizationNames[] = [] ;
    for (let i = 0 ; i < 26 ; i++) {
        sections[i] = { items:[], letter:String.fromCharCode(65+i) } ;
    } 
    sections[26] = { items:[], letter:'?' } ;

    const sortedCorporations = corporations.sort(_compareCorporations) ;
    sortedCorporations.forEach(c => {
        const name = $trim(c.name) ;
        if (name.length) {
            let index = $ascii(name.charAt(0)).toUpperCase().charCodeAt(0)-65 ;
            if (index < 0 || index >= 26) { index = 26 ; }
            sections[index].items.push(c) ;
        }
    }) ;

    return sections.filter(s => s.items.length > 0) ;
} ;