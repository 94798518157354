import { Router } from "@reach/router" ;
import { PageProps } from "gatsby" ;

import React from "react" ;

import { AuthenticatedLayout } from "../../components/layouts/AuthenticatedLayout";
import PrivateRoute from "../../components/PrivateRoute";

import { CorporationsView } from "../../containers/admin/CorporationsView";
import { ConsumptionsView } from "../../containers/admin/ConsumptionsView";
import { OtpView } from "../../containers/admin/OtpView";
import { UsersView } from "../../containers/admin/UsersView" ;

const Admin = (props: PageProps) => {
    return (
        <Router basepath="/admin">
            <PrivateRoute
                path="/corporations"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <CorporationsView maxW={400} />
                    </AuthenticatedLayout>
                )}
                adminPage={true}
            />
            <PrivateRoute
                path="/users"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <UsersView maxW={400} />
                    </AuthenticatedLayout>
                )}
                adminPage={true}
            />
            <PrivateRoute
                path="/consumptions"
                component={() => (
                    <AuthenticatedLayout {...props as any}>
                        <ConsumptionsView maxW={400} />
                    </AuthenticatedLayout>
                )}
            />
            <PrivateRoute
                path="/check-otp"
                component={() => (
                    <OtpView />
                )}
                adminPage={true}
            />
        </Router>
    ) ;
} ;

export default Admin ;
