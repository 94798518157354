import { StyleProps, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { $ok } from "foundation-ts/commons";
import { Nullable, UUID } from "foundation-ts/types";
import { ConsumptionsStructure } from "g1-commons/lib/doxecureTypes";
import { UserDto } from "g1-commons/lib/doxecureClientTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { ColumnTitle } from "../../components/ColumnTitle/ColumnTitle";
import { ConsumptionsBox } from "../../components/ConsumptionsBox/ConsumptionsBox";
import { PageShellWithThreeColumns } from "../../components/PageShellWithThreeColumns/PageShellWithThreeColumns";
import { config } from "../../config/config";
import { exportCorporationConsumptions, getCorporationConsumptions, getCorporationCurrentConsumption } from "../../services/corporations.service";
import { exportConsumptions, getConsumptions, getCurrentConsumption } from "../../services/stats.service";
import { defaultErrorToast } from "../../utils/toast";
import { forceLogout, isAdminUser } from "../../utils/functions";

interface ConsumptionsViewProps extends StyleProps {
    connectedUserProfile?: UserDto ;
}

export const ConsumptionsView = ({ maxWidth, connectedUserProfile }: ConsumptionsViewProps) => {
    const toast = useToast() ;
    const [nbQualified, setNbQualified] = useState<number>(0) ;
    const [nbAdvanced, setNbAdvanced] = useState<number>(0) ;
    const [consumptions, setConsumptions] = useState<ConsumptionsStructure|null>(null) ;
    const [corporationName, setCorporationName] = useState<Nullable<string>>(null) ;
    const [corporationId, setCorporationId] = useState<Nullable<UUID>>(null) ;

    useEffect(() => {
        const currentConsumption = async (corporateId: Nullable<UUID>) => {
            const consumptions = $ok(corporateId) ? await getCorporationCurrentConsumption(corporateId!) : await getCurrentConsumption() ;
            setNbQualified($ok(consumptions) ? consumptions!.qualified : -1) ;
            setNbAdvanced($ok(consumptions) ? consumptions!.advanced : -1) ;
        }

        const consumptions = async (corporateId: Nullable<UUID>) => {
            const data = $ok(corporateId) ? await getCorporationConsumptions(corporateId!) : await getConsumptions() ;
            setConsumptions($ok(data) ? data! : null) ;
        }

        if ($ok(connectedUserProfile)) {
            if (isAdminUser(connectedUserProfile?.userFlags?.profile)) {
                const corporation = connectedUserProfile!.corporations?.first() ;
                currentConsumption(corporation?.apid) ;
                consumptions(corporation?.apid) ;
                setCorporationId(corporation?.apid) ;
                setCorporationName(corporation?.name) ;
            } else {
                toast(defaultErrorToast("Vous n'êtes pas autorisé à accéder à cette page")) ;
                forceLogout(config.app.adminLoginURL!) ;
            }
        }
    }, [connectedUserProfile]) ;

    const onExportConsumptions = async () => {
        try {
            if ($ok(corporationId)) {
                exportCorporationConsumptions(corporationId!, corporationName!) ;
            } else {
                exportConsumptions() ;
            }
        } catch (error) {
            toast(defaultErrorToast("Problème rencontré lors de l'export des données")) ;
        }
    }

    return (
        <PageShellWithThreeColumns
          centralContent={
              <>
              <ColumnTitle color={$g1Color('global.write')}>
                  <Text>Consommations</Text>
              </ColumnTitle>
              <ConsumptionsBox maxWidth={maxWidth} nbAdvanced={nbAdvanced} nbQualified={nbQualified} consumptions={consumptions} onExport={onExportConsumptions} />
              </>
          }
        />
    );
} ;