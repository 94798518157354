import { Select, VStack } from "@chakra-ui/react";
import React, { useRef } from "react";

import { $ok } from "foundation-ts/commons";
import { Nullable } from "foundation-ts/types";
import { CorporationDto } from "g1-commons/lib/doxecureClientTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { AsyncActionModal } from "./AsyncActionModal";
import { ModalWindowState } from "./ModalConstants";

export const DefaultAsyncLinkUserModalState:AsyncLinkUserModalState = {
    items: null,
    isOpen: false,
    onAccept: async () => {},
    onClose: async () => {},
} ;


export interface AsyncLinkUserModalState extends ModalWindowState {
    items: Nullable<CorporationDto[]>;
    isOpen: boolean;
    onAccept: (corporation: Nullable<CorporationDto>) => Promise<void>;
}

export const AsyncLinkUserModal = (props: AsyncLinkUserModalState) => {
    const { items, isOpen, size = 'md', onClose, onAccept } = props ;
    const selectElement= useRef<HTMLSelectElement>(null) ;

    const linkMessage = ["Le rattachement d'un utilisateur à une organisation impose les actions suivantes :",
        "1. Le compte actuel sera désactivé, un nouvel accès lui sera fourni avec les identifiant et mot de passe actuels.",
        "2. Toutes les sessions en cours de signature dont il fait partie seront annulées.",
        "3. S'il est Administrateur de la plateforme, alors il conserve ce rôle."
    ].join(' ') ;

    const handleConfirm = () => {
        onAccept(!$ok(selectElement.current) || !$ok(items) ? null : items![selectElement.current!.selectedIndex]) ;
    }

    const renderSelectCorporation = () => {
        return (
            <VStack margin="auto">
                <Select ref={selectElement} bg={$g1Color('form.field.bg')} borderColor={$g1Color('form.field.border')} isRequired autoComplete="true">
                    {$ok(items) && items!.map((corporation) => (
                        <option value={corporation.apid} key={corporation.apid}>
                            {corporation.name} ({corporation.corporationIdentifier})
                        </option>
                    ))}
                </Select>
            </VStack>
        ) ;
    }

    return <AsyncActionModal title="Veuillez sélectionner l'organisation de rattachement" message={linkMessage} confirm="Rattacher" cancel="Annuler" size={size} isOpen={isOpen} element={renderSelectCorporation()} onClose={onClose} onAccept={handleConfirm} /> ;
} ;