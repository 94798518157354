import React, { useState } from "react";

import { G1Icon } from "../../utils/icons";

interface IconProperties {
    size: string ;
    onClick: () => void ;
}

export const AddDomainMailIcon = (props: IconProperties) => {
    const {size, onClick} = props ;
    const [isHover, setIsHover] = useState(false) ;

    return <G1Icon.Plus size={size} style={{color: "teal", cursor: "pointer"}} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onClick={onClick} /> ;
}

export const DeleteDomainMailIcon = (props: IconProperties) => {
    const {size, onClick} = props ;
    const [isHover, setIsHover] = useState(false) ;
    
    return <G1Icon.Delete size={size} style={{color: "indianred", cursor: "pointer"}} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onClick={onClick} /> ;
}